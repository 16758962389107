import React from 'react';
import axios from 'axios';
import {Popup, RadioButton} from "@partssourceinc/react-ui-core";
import Select from 'react-select'
import * as UserStore from 'stores/User';
import {connect} from 'react-redux';
import _ from 'lodash';

import 'less/AdvancedLists/sharedListDialog.less'

@connect(
    state => ({
        params: state.params,
    }),
    _.merge({},
        UserStore.actionCreators
    )
)
export default class ShareListDialog extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.state = {
            contactList: [],
            contacts: [],
            shareType: '',
            loading: false,
        };
    }
    
    componentDidMount() {
        this.getContacts();
    }

    onShareList() {
    
        let {contacts, shareType} = this.state;
        const {list, facility, shareList, getLists} = this.props;
  
        let numberOfShares = 1;
        let shareRequest = {};            
        shareRequest.Id = list.id;
        shareRequest.ravenId = list.ravenId;
        shareRequest.facilityId = facility.facilityId;
        shareRequest.facilityName = facility.facilityName;
    
        if (shareType == 'contacts') {
            shareRequest.contactIds = [];
            shareRequest.contacts = [];
            contacts.forEach(c => {
                shareRequest.contactIds.push(c.contactId);
                shareRequest.contacts.push(c.fullName);
            });
        }

        switch (shareType) {
            case 'contacts':
                shareRequest.shareType = 1;
                numberOfShares = shareRequest.contactIds.length;
                break;
            case 'facility':
                shareRequest.shareType = 2;
                break;
            case 'company':
                shareRequest.shareType = 3;
                break;
        }

        this.setState({loading: true});
      
        shareList(shareRequest,numberOfShares).then(() => {
            getLists().then(() => {
                this.props.onSave(numberOfShares);
            })
        });
    }

    onCancel() {
    
        this.props.onCancel();    
    }

    onSelectContact() {
        const {onSubmit} = this.props;
        onSubmit(contact);
    } 

    getContacts() {

        axios.get(`/SettingsService/api/v1/contact/list`).then(x => {                
            this.setState({contactList: x.data})            
        });
    }

    shareTypeChange(e, data) {
        let {shareType} = this.state;
        shareType = data.value;
        this.setState({shareType});
    }

    onContactChange(values) {
        
        let {contacts} = this.state;
        if (values != null) {
            contacts = values;          
        } else {
            contacts = [];          
        }
        this.setState({contacts});
    }

    render() {

        let {contactList, contacts, shareType, loading} = this.state;
        const {list} = this.props; 
    
        return (
            <Popup confirmText="Share List" 
                cancelText="Cancel" 
                className="share-list-dialog"
                show={true} 
                onConfirm={::this.onShareList}
                loading={loading}
                hideButtons={false}
                onCancel={::this.onCancel}
                disableConfirm={!shareType}
                disableClose={false}> 
                <h2 className="shareListName">Share List: {list.name}</h2>
                <div className="row share-list-row">
                    <div className="col-md-12">
                        
                        <RadioButton
                            checked={shareType === 'contacts'}
                            tabIndex="1"
                            onChange={::this.shareTypeChange}
                            id="contacts"
                            name="shareType"
                            label="Specific People"
                            value="contacts"
                            required={true}
                        />
                        <label className="lbl-share-with">Share with only certain people within your organization.</label>
                        {shareType === 'contacts' && <div className="advanced-list-contacts-selector"><Select
                            isMulti={true}
                            disabled={true}    
                            autoFocus={false}                      
                            cacheOptions={true}
                            options={contactList}
                            getOptionValue={option => option.contactId}
                            getOptionLabel={option => option.fullName}
                            noOptionsMessage={() => null}
                            placeholder=""
                            classNamePrefix="select"
                            value={contacts}
                            tabIndex="2"
                            onChange={::this.onContactChange}                        
                        /></div> }
                        <RadioButton
                            checked={shareType === 'facility'}
                            tabIndex="3"
                            onChange={::this.shareTypeChange}
                            id="facility"
                            name="shareType"
                            label="My Facility"
                            value="facility"
                            className="radio-button"
                            required={true}
                        />
                        <label className="lbl-share-with">Share with others at your facility.</label>
                        <RadioButton
                            checked={shareType === 'company'}
                            tabIndex="4"
                            onChange={::this.shareTypeChange}
                            id="company"
                            name="shareType"
                            label="My Company"
                            value="company"
                            className="radio-button"
                            required={true}
                        />
                        <label className="lbl-share-with">Share with others within your organization.</label>
                    </div>               
                </div> 
            </Popup>
        );
    }
}
