import React from 'react';
import {NavLink} from 'react-router-dom';
import 'less/advancedLists.less';

export default function EmptyList() {
    return (
        <div style={{marginTop: 45, display: 'inline-block'}}>
            <span className="empty-list"> Your list is currently empty. </span>
            <NavLink className="start-shopping" to={`/`}>Start Shopping</NavLink>
        </div>
    );
}
