import React from 'react';
import {useHistory} from 'react-router-dom';
import 'less/advancedLists.less';

export default function CreateNewList(props) {
    const history = useHistory();
    return (
        <div onClick={() => history.push(`/lists/create`)} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="tab-item create-new">
                <span className="circle">+</span>
                <div className="title create">
                    Create New List
                </div>
                <span className="subtitle hidden-xs" >
                Create shopping lists, repair lists, preventative maintanence lists, or restocking lists
                </span>
            </div>
        </div>
    );
}
