import React from 'react';
import {SortableHandle} from 'react-sortable-hoc';
import {useHistory} from 'react-router-dom';
import 'less/advancedLists.less';
import {useSelector} from 'react-redux';

export default function ListSummary({list, currentTab, handleOnShare}) {
    const isListAdmin = useSelector(state => state.user.settings.isListAdmin);
    
    const DragHandle = SortableHandle(() => (
        <span className="draggable hidden-xs">
            <span className="material-icons swap-vert" style={{fontSize: 14}}>
        swap_vert
            </span>
        </span>
    ));

    const getListType = () => {
        if (list.isRestockingList) return 'restocking';
        if (list.isShoppingList) return 'shopping';
        if (list.isRepairList) return 'repair';
        if (list.isPreventativeMaintenanceList) return 'preventative maintanenance';
    };

    const history = useHistory();
    return (
        <div
            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
        >
            <div className="tab-item">
                <div>
                    {list.isPartsSourceList && (
                        <span className="psLogo" style={{marginRight: (currentTab === 'mylist' || isListAdmin) ? 40 : 25}}>PS
                            <span className="tooltipText">
                                <span className="text">
                                  This public list is curated and maintained by PartsSource for
                                  your convenience.
                                </span>
                            </span>
                        </span>
                    )}
                    {(currentTab === 'mylist' || isListAdmin) && <DragHandle />}
                </div>
                <div className="row">
                    <div className="tab-item-body main" onClick={() => history.push(`/list/${list.ravenId}`)}>
                        <div className="title">{list.name}</div>
                        <div className={`list-badge ${getListType()}`}>
                            <span className="text">{getListType()}</span>
                        </div>
                        <div className="itemCount">{list.itemCount} items</div>
                    </div>
                    <div className="bottom-row">
                        <div className="tab-item-body" onClick={() => handleOnShare(list)}>
                            <span className="fa fa-share shareIcon" />
                            <span className="shareCount">{list.numberOfShares}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
