import React, {useState, useEffect} from 'react';
import PageMetaData from 'components/PageMetaData';
import TabStrip from 'components/TabStrip';
import 'less/AdvancedLists/lists.less';
import ListSummary from 'pages/AdvancedLists/ListSummary';
import {useHistory, useLocation} from 'react-router-dom';
import {getLocationQuery} from '../../utility';
import {useSelector, useDispatch} from 'react-redux';
import {SortableContainer, SortableElement} from '../../lib/react-sortable-hoc';
import _ from 'lodash';
import 'less/advancedLists.less';
import {actionCreators} from 'stores/User';
import CreateNewList from './CreateNewList';
import EmptyList from './EmptyList';
import {useCallback} from 'react';
import {Button} from '@partssourceinc/react-ui-core/components';
import ShareListDialog from './ShareListDialog';

export default function Lists() {
    const dispatch = useDispatch();
    const {sortLists, getLists} = actionCreators;
    const facility = useSelector(state => state.user.facility);
    const history = useHistory();
    const lists = useSelector((state) => state.user.lists);
    const isListAdmin = useSelector(state => state.user.settings.isListAdmin);
    const showPartsSourceLists = useSelector(state => state.user.settings.showPartsSourceLists)

    const location = useLocation();
    const query = getLocationQuery(location);
 
    const [loading] = useState(false);
    const [currentTab, setCurrentTab] = useState('mylist');
    const [searchTerm, setSearchTerm] = useState('');
    const [showListTypeMenu, setShowListTypeMenu] = useState(false);
    const [showSortByMenu, setShowSortByMenu] = useState(false);
    const [showTabViewMenu, setShowTabViewMenu] = useState(false);
    const [listType, setListType] = useState('All');
    const [sortType, setSortType] = useState('Most Viewed');
    const [allLists, setAllLists] = useState(null);
    const [listLength, setListLength] = useState(12)
    const [toBeSharedList, setToBeSharedList] = useState(null);
    const [showShareDialog, setShowShareDialog] = useState(false)
    const [listSorts, setListSorts] = useState({partssource: 'Most Viewed', company: 'Most Viewed'});

    useEffect(() => {
        dispatch(getLists());
    }, []);

    useEffect(() => {
        if (query.tab) {
            setListType('All');
            setCurrentTab(query.tab.toLowerCase());
            setListLength(query.tab.toLowerCase() === 'mylist' ? 11 : 12);
        }
    }, [query.tab]);

    const getDefaultList = () => {
        let data = searchTerm ? lists.filter((x) => x.search.indexOf(searchTerm) > -1) : lists;
        return {
            mylist: data.filter((x) => x.groupName === 'mylist'),
            partssource: data.filter((x) => x.groupName === 'partssource'),
            company: data.filter((x) => x.groupName === 'company'),
        };
    };

    useEffect(() => {
        let data = {...getDefaultList()};
        applyFilter(data);
        applySort(data);

        setAllLists(data);
    }, [listType, currentTab, sortType, searchTerm, lists]);

    const tabKeys = [
        {
            id: 'mylist',
            fieldName: 'groupName',
            value: 'mylist',
            tabDisplay: 'My Lists',
        },
        {
            id: 'partssource',
            fieldName: 'groupName',
            value: 'partssource',
            tabDisplay: 'PartsSource Lists',
        },
        {
            id: 'company',
            fieldName: 'groupName',
            value: 'company',
            tabDisplay: 'Company Lists',
        },
    ];

    const onTabChange = (tab) => {
        setShowSortByMenu(false);
        setShowListTypeMenu(false);
        setShowTabViewMenu(false);
        history.push(`/lists?tab=${tab.id}`);
    };

    const onChangeListType = (e) => {
        setListType(e.target.innerText);
        setShowListTypeMenu(false);
    };

    const onChangeSortType = (e) => {
        persistSort(e.target.innerText)
        setShowSortByMenu(false);
    };

    const onShareListSave = (countShares) => {   
        setShowShareDialog(false);
    };

    const sortItems = (oldIndex, newIndex, list) => {
        const movedItem = list.find((item, index) => index === oldIndex);
        const remainingItems = list.filter((item, index) => index !== oldIndex);

        const reorderedItems = [
            ...remainingItems.slice(0, newIndex),
            movedItem,
            ...remainingItems.slice(newIndex),
        ];

        return reorderedItems;
    };

    const applyFilter = data => {
        switch (listType) {
            case 'Shopping':
                data[currentTab] = data[currentTab].filter((x) => x.isShoppingList);
                break;
            case 'Repair':
                data[currentTab] = data[currentTab].filter((x) => x.isRepairList);
                break;
            case 'Preventative Maintenance':
                data[currentTab] = data[currentTab].filter(
                    (x) => x.isPreventativeMaintenanceList
                );
                break;
            case 'Restocking':
                data[currentTab] = data[currentTab].filter((x) => x.isRestockingList);
                break;
            case 'All':
                data = {...getDefaultList()};
        }
    };

    const applySort = data => {
        if (currentTab !== 'mylist' && !isListAdmin) {
            if (listSorts[currentTab] === 'Most Viewed') {
                data[currentTab] = _.orderBy(data[currentTab], ['numberOfViews'],['desc']);
            } else if (listSorts[currentTab] === 'Most Recent') {
                data[currentTab] = _.orderBy(data[currentTab], ['dateCreated'], ['desc']);
            } else {
                data[currentTab] = _.orderBy(data[currentTab], ['sortOrder']);
            }
        }
    };
    const persistSort = sort => {
        let sorts = {...listSorts};
        sorts[currentTab] = sort;
        setListSorts(sorts);
        setSortType(sort)
    };
    
    const getJoinedElements = () => {
        if (allLists)
            return [
                ...allLists.mylist,
                ...allLists.partssource,
                ...allLists.company,
            ];
        return [];
    };

    const handleOnShare = list => {
        setToBeSharedList(list);
        setShowShareDialog(true);
    };

    const handleOnSort = useCallback((e) => {
        const {oldIndex, newIndex} = e;
        let data = {...allLists};

        data[currentTab] = sortItems(oldIndex, newIndex, data[currentTab]);

        dispatch(sortLists(data[currentTab])).then(() => {
            dispatch(getLists());
        });

        setAllLists(data);
    });

    const SortableItem = SortableElement(({value, currentTab}) => (
        <ListSummary list={value} currentTab={currentTab} handleOnShare={handleOnShare} />
    ));

    const SortableList = SortableContainer(({items,listLength}) => {
        return (
            <div style={{width: '100%'}}>
                <div className="col-md-12 " style={{padding: 0}}>
                    <div className="col-md-12 action-row desktop" style={{marginBottom: '25px'}}>
                        <span className="select-wrapper">
                            <label>List Type:</label>
                            <span className="select" onClick={() => {
                                setShowListTypeMenu(!showListTypeMenu); setShowSortByMenu(false);
                            }}>
                                {listType}
                            </span>
                            {showListTypeMenu && !loading && (
                                <span className="list-menu" onMouseLeave={() => setShowListTypeMenu(false)}>
                                    <span onClick={onChangeListType} className={listType === 'All' ? 'selected' : ''}>All</span>
                                    <span onClick={onChangeListType} className={listType === 'Shopping' ? 'selected' : ''}>Shopping</span>
                                    <span onClick={onChangeListType} className={listType === 'Repair' ? 'selected' : ''}>Repair</span>
                                    <span onClick={onChangeListType} className={listType === 'Preventative Maintenance' ? 'selected' : ''}>Preventative Maintenance</span>
                                    <span onClick={onChangeListType} className={listType === 'Restocking' ? 'selected' : ''}>Restocking</span>
                                </span>
                            )}
                        </span>
                        {currentTab !== 'mylist' && !isListAdmin && (
                            <span className="select-wrapper mobile-select">
                                <label>Sort By:</label>
                                <span className="select" onClick={() => {
                                    setShowSortByMenu(!showSortByMenu); setShowListTypeMenu(false);
                                }}>{listSorts[currentTab]}</span>
                                {showSortByMenu && (
                                    <span className="list-menu" onMouseLeave={() => setShowSortByMenu(false)}>
                                        <span onClick={onChangeSortType} className={listSorts[currentTab] === 'Most Viewed' ? 'selected' : ''}>Most Viewed</span>
                                        <span onClick={onChangeSortType} className={listSorts[currentTab] === 'Most Recent' ? 'selected' : ''}>Most Recent</span>
                                    </span>
                                )}
                            </span>
                        )}
                    </div>
                    {currentTab === 'mylist' && <CreateNewList />}
                    {items && items.length > 0 ? (
                        items.map((value, index) => index < listLength ? <SortableItem key={`item-${index}`} currentTab={currentTab} index={index} value={value} /> : null)
                    ) : currentTab === 'mylist' ? null : (
                        <EmptyList />
                    )}
                </div>
                {items && items.length > 11 ? items.length === listLength ? null : <div className="col-md-12 showMore">
                    <Button secondary={true} onClick={() => setListLength(items.length)}>Show More</Button>
                </div> : null}
            </div>
        );
    });

    return (
        <div className="list-summary-page">
            <PageMetaData title="My Lists" pageType="other" trackAnalytics={true} />
            <h1>My Lists</h1>
            {loading && (
                <div className="loading-data">
                    <div className="loader" />
                </div>
            )}
            <div className="action-row full-row" style={{border: 0}}>
                <span className="search-wrapper" >
                    <input
                        type="search"
                        autoComplete="grid-search"
                        className="hidden-search-box"
                        placeholder="Find"
                        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                    />
                    <i className="glyphicon glyphicon-search" />
                </span>
            </div>
            <TabStrip data={getJoinedElements()} tabKeys={showPartsSourceLists ? tabKeys : tabKeys.filter(tab => tab.id !== 'partssource')} currentTab={currentTab} onChange={onTabChange} />
            <hr className="full-row d-block d-sm-none" />
            <span className="select-wrapper tab-view">
                <label>View:</label>
                <span className="select" onClick={() => {
                    setShowTabViewMenu(!showTabViewMenu);
                }}>
                    {`${tabKeys.find((x) => x.id === currentTab).tabDisplay} (${
                        allLists ? allLists[currentTab].length : 0
                    })`}
                </span>
                {showTabViewMenu && !loading && (
                    <span className="list-menu" onMouseLeave={() => setShowTabViewMenu(false)}>
                        <span onClick={() => onTabChange({id: 'mylist'})} className={currentTab === 'mylist' ? 'selected' : ''}>My List</span>
                        <span onClick={() => onTabChange({id: 'partssource'})} className={listType === 'partssource' ? 'selected' : ''}>PartsSource Lists</span>
                        <span onClick={() => onTabChange({id: 'company'})} className={listType === 'company' ? 'selected' : ''}>Company Lists</span>
                    </span>
                )}
            </span>
            <span className="select-wrapper tab-view list-type">
                <label>List Type:</label>
                <span className="select" onClick={() => {
                    setShowListTypeMenu(!showListTypeMenu); setShowSortByMenu(false);
                }}>
                    {listType}
                </span>
                {showListTypeMenu && !loading && (
                    <span className="list-menu" onMouseLeave={() => setShowListTypeMenu(false)}>
                        <span onClick={onChangeListType} className={listType === 'All' ? 'selected' : ''}>All</span>
                        <span onClick={onChangeListType} className={listType === 'Shopping' ? 'selected' : ''}>Shopping</span>
                        <span onClick={onChangeListType} className={listType === 'Repair' ? 'selected' : ''}>Repair</span>
                        <span onClick={onChangeListType} className={listType === 'Preventative Maintenance' ? 'selected' : ''}>Preventative Maintenance</span>
                        <span onClick={onChangeListType} className={listType === 'Restocking' ? 'selected' : ''}>Restocking</span>
                    </span>
                )}
            </span>
            <div style={{display: 'flex'}}>
                <SortableList
                    listLength={listLength}
                    onSortEnd={handleOnSort}
                    items={allLists ? allLists[currentTab] : null}
                    allowEdit={true}
                    lockToContainerEdges={true}
                    axis={'xy'}
                    useDragHandle={true}
                    useWindowAsScrollContainer={true}
                    helperClass="sortableHelper" />
            </div>

            {showShareDialog && <ShareListDialog facility={facility} list={toBeSharedList} onSave={onShareListSave} onCancel={() => setShowShareDialog(false)} />}

        </div>
    );
}
